var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"sS0dKHwHT_MytQs_DWvJr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { isSentryActive, isSentryDebug } from '@/services/Utils/Sentry';

if (isSentryActive) {
	Sentry.init({
		debug: isSentryDebug,
		denyUrls: [
			'https://home-e31.niceincontact.com',
			'https://api-e31.niceincontact.com',
			'https://widget.trustpilot.com',
			'https://events.statsigapi.net',
			'https://www.google.com/recaptcha',
			'https://www.gstatic.com/recaptcha/',
			'https://www.googletagmanager.com/gtm.js?id=GTM-N94CQV',
			'https://www.googletagmanager.com/gtag/js?id=AW-601638299',
			'https://consent.cookiebot.com/uc.js',
			'https://static.hotjar.com/c/hotjar-905264.js?sv=7',
			'https://maps.googleapis.com/',
		],
		dsn: 'https://0858151624e34ab8811d3b3dcd08b9f2@o1125533.ingest.sentry.io/6165523',
		environment: process.env.NEXT_PUBLIC_ENV,
		ignoreErrors: [
			"Cannot read properties of undefined (reading 'hpm')",
			"Cannot read properties of undefined (reading 'brackets')",
			"Cannot read properties of undefined (reading 'site_id')",
			"Cannot read properties of undefined (reading 'logging')",
			"Cannot read properties of null (reading 'parentNode')",
			"Cannot read properties of null (reading 'setItem')",
			"Cannot read properties of null (reading 'type')",
			"Cannot read properties of undefined (reading 'size')",
			"Cannot read properties of null (reading 'style')",
			"Cannot read properties of undefined (reading 'nativeBack')",
			"Cannot read properties of null (reading 'removeChild')",
			"Cannot read properties of null (reading 'shadowRoot')",
			"Cannot read properties of null (reading 'disconnect')",
			"Cannot read properties of null (reading 'appendChild')",
			"Cannot read properties of null (reading 'classList')",
			"Cannot read properties of undefined (reading 'lat')",
			'Cannot redefine property: googletag',
			"undefined is not an object (evaluating 'window.___grecaptcha_cfg[u]')",
			"undefined is not an object (evaluating 'window.___grecaptcha_cfg.logging')",
			"undefined is not an object (evaluating 'this.getPanes().overlayMouseTarget')",
			"undefined is not an object (evaluating 'consentDetailsContainer.classList')",
			"undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
			"undefined is not an object (evaluating 'n.length')",
			"undefined is not an object (evaluating '__gCrWeb.edgeTranslate.detectPageState')",
			"undefined is not an object (evaluating '__gCrWeb.instantSearch.clearHighlight')",
			"null is not an object (evaluating 'window.localStorage.getItem')",
			"null is not an object (evaluating 'this._logicalFormForControl(e).formElement')",
			"null is not an object (evaluating 'cybotCookiebotDialogBodyButtonAccept.id=isMultiBtton?*",
			"null is not an object (evaluating 'this._logicalFormForControl(e).formElement')",
			"null is not an object (evaluating 'ti.sequence')",
			"null is not an object (evaluating 'e.createToken')",
			'WKWebView API client did not respond to this postMessage',
			"Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
			"undefined is not an object (evaluating 'b.container[a]')",
			"t.__ is not a function. (In 't.__()', 't.__' is undefined)",
			"getPrototypeOf' on proxy: trap returned neither object nor null",
			'Dv().getRestrictions is not a function',
			"Hv().getRestrictions is not a function. (In 'Hv().getRestrictions(1,a)', 'Hv().getRestrictions' is undefined)",
			'Member PerformanceObserverInit.entryTypes is required and must be an instance of sequence',
			"undefined is not an object (evaluating '__gCrWeb.instantSearch.setIOSParameters')",
		],
		integrations: [
			new Sentry.Replay({
				blockAllMedia: false,
				maskAllInputs: true,
				maskAllText: false,
				networkDetailAllowUrls: [
					'https://www.bumper.co/core/payment/payment/paylater/authorize',
					'https://www.bumper.co/core/payment/payment/paylater/authorize/details',
					'https://www.bumper.co/core/application/payment-request/detail',
					'https://www.bumper.co/core/application/update',
					/.*bumper\.co.*/,
				],
			}),
		],
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		tracesSampleRate: isSentryDebug ? 1 : 0.5,
	});
}
